<template>
  <div v-if="permission.access">
    <div class="w-100 text-right">
      <b-dropdown
        v-if="permission.delete"
        text="Action"
        variant="outline-primary"
        class="mb-2"
        :disabled="!select.selected"
      >
        <b-dropdown-item @click="Delete"> Delete </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="row">
      <div class="col-md-8">
        <b-card no-body>
          <div class="m-2">
            <b-row>
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <!-- :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" -->
                <v-select
                  @on-change="paginateChange"
                  :options="perPageOptions"
                  v-model="search.paginate"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="mb-1 d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="search.search_data"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                </div>
              </b-col>

              <!-- Start From Date -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <DatePicker
                      format="yyyy-MM-dd"
                      type="date"
                      name="from_date"
                      v-model="search.from_date"
                      id="from_date"
                      placeholder="Start Date From"
                      :clearable="true"
                      style="width: 100%"
                  ></DatePicker>
                </div>
              </b-col>

              <!-- Start To Date -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <DatePicker
                      format="yyyy-MM-dd"
                      type="date"
                      name="from_date"
                      v-model="search.to_date"
                      id="from_date"
                      placeholder="Start Date From"
                      :clearable="true"
                      style="width: 100%"
                  ></DatePicker>
                </div>
              </b-col>

            </b-row>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="align-middle text-center">Sl</th>
                  <th class="text-center">Image</th>
                  <th>Name</th>
                  <th>Details</th>
                  <th>Status</th>
                  <th class="text-center" v-if="permission.edit">Action</th>
                  <th class="align-middle" v-if="permission.delete">
                    <label
                      class="custom-control custom-checkbox mb-0 d-inline-block"
                    >
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="checkAllDataTables"
                        v-model="selectedAll"
                        @click="selectAll"
                      />
                      <span class="custom-control-label">&nbsp;</span>
                    </label>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(teacher, index) in teachers.data"
                  :key="index"
                  v-if="teachers.data.length"
                >
                  <td class="align-middle text-center">
                    {{ teachers.from + index }}
                  </td>
                  <td class="align-middle text-center">
                    <img
                      :src="
                        teacher.image
                          ? $store.state.base_url + teacher.image
                          : set_image
                      "
                      width="70"
                      height="70"
                      alt=""
                    />
                  </td>
                  <td class="align-middle">
                    <b>{{ teacher.name }}</b>
                  </td>
                  <td class="align-middle">
                    <p class="mb-0" v-if="teacher.profile">
                      <b>Company : </b> {{ teacher.profile.company }}
                    </p>
                    <p class="mb-0" v-if="teacher.profile">
                      <b>Profession : </b> {{ teacher.profile.profession }}
                    </p>
                    <p class="mb-0" v-if="teacher.profile">
                      <b>Designation : </b> {{ teacher.profile.designation }}
                    </p>
                  </td>
                  <td class="align-middle">
                    <p class="mb-0">
                      <span
                        style="margin-right: 4px"
                        class="badge"
                        :class="
                          teacher.status == 1 ? 'badge-success' : 'badge-danger'
                        "
                      >
                        {{ teacher.status == 1 ? "Active" : "Inactive" }}
                      </span>
                    </p>
                  </td>
                  <td class="align-middle text-center">
                    <div class="btn-group">
                      <button
                        v-if="permission.edit"
                        class="btn btn-primary btn-sm"
                        @click="edit(teacher.id)"
                      >
                        <feather-icon icon="EditIcon" />
                      </button>
                    </div>
                  </td>
                  <td v-if="permission.delete" class="align-middle">
                    <b-form-checkbox
                      v-model="select.data"
                      :value="teacher.id"
                      name="flavour-3a"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ teachers.from ? teachers.from : 0 }} to
                  {{ teachers.to ? teachers.to : 0 }} of
                  {{ teachers.total ? teachers.total : 0 }}
                  entries</span
                >
              </b-col>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <pagination
                  :data="teachers"
                  @pagination-change-page="getResults"
                  align="right"
                  :show-disabled="true"
                  :limit="5"
                ></pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
      <div class="col-md-4" v-if="permission.create || form.id != ''">
        <form @submit.prevent="add" @keydown="form.onKeydown($event)">
          <b-card :title="(form.id == '' ? 'Create' : 'Update') + ' Teacher'">
            <b-card-text>
              <div class="form-group">
                <label for="name">Name</label>
                <Input
                  :class="{
                    'is-invalid border-danger': form.errors.has('name'),
                  }"
                  name="name"
                  type="text"
                  v-model="form.name"
                  id="name"
                  placeholder="Enter Teacher Name"
                />
                <has-error :form="form" field="name"></has-error>
              </div>
              <div class="form-group">
                <label for="email">Email</label>
                <Input
                  :class="{
                    'is-invalid border-danger': form.errors.has('email'),
                  }"
                  name="email"
                  type="email"
                  v-model="form.email"
                  id="email"
                  placeholder="Enter Teacher Email"
                />
                <has-error :form="form" field="email"></has-error>
              </div>
              <div class="form-group">
                <label for="profession">Profession</label>
                <Input
                  :class="{
                    'is-invalid border-danger': form.errors.has('profession'),
                  }"
                  name="profession"
                  type="text"
                  v-model="form.profession"
                  id="profession"
                  placeholder="Enter Teacher Profession"
                />
                <has-error :form="form" field="profession"></has-error>
              </div>
              <div class="form-group">
                <label for="designation">Designation</label>
                <Input
                  :class="{
                    'is-invalid border-danger': form.errors.has('designation'),
                  }"
                  name="designation"
                  type="text"
                  v-model="form.designation"
                  id="designation"
                  placeholder="Enter Teacher Designation"
                />
                <has-error :form="form" field="designation"></has-error>
              </div>
              <div class="form-group">
                <label for="company">Company Name</label>
                <Input
                  :class="{
                    'is-invalid border-danger': form.errors.has('company'),
                  }"
                  name="company"
                  type="text"
                  v-model="form.company"
                  id="company"
                  placeholder="Enter Company Name"
                />
                <has-error :form="form" field="company"></has-error>
              </div>
              <div class="form-group">
                <label for="description">Description</label>
                <Input
                  :class="{
                    'is-invalid border-danger': form.errors.has('description'),
                  }"
                  type="textarea"
                  :rows="3"
                  name="description"
                  v-model="form.description"
                  id="description"
                  placeholder="Enter Description"
                />
                <has-error :form="form" field="description"></has-error>
              </div>

              <div class="form-group">
                <label for="password">Enter Teacher Login Password</label>
                <Input
                  :class="{
                    'is-invalid border-danger': form.errors.has('password'),
                  }"
                  name="password"
                  type="text"
                  v-model="form.password"
                  id="password"
                  placeholder="Enter Teacher Login Password"
                />
                <has-error :form="form" field="password"></has-error>
              </div>
              <div class="border rounded p-2 mb-2">
                <b-media
                  no-body
                  vertical-align="center"
                  class="align-items-center text-center"
                >
                  <label for="image" class="text-center w-100">
                    <h4 class="mb-2"><b>Select Teacher Profile Image</b></h4>
                    <b-img
                      for="image"
                      :src="image"
                      height="300"
                      width="300"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </label>
                  <b-form-file
                    class="d-none"
                    id="image"
                    accept=".jpg, .png, .gif"
                    @change="loadImage($event)"
                    placeholder="Select Teacher image"
                    drop-placeholder="Drop file here..."
                  />
                </b-media>
              </div>

              <b-form-checkbox
                name="status"
                style="margin-top: 5px"
                v-model="form.status"
                switch
                inline
              >
                Status
              </b-form-checkbox>
              <b-button
                type="submit"
                class="float-right"
                variant="primary"
                :disabled="form.busy"
              >
                {{ form.id == "" ? "Create" : "Update" }}
              </b-button>
              <b-button
                type="button"
                class="float-right mx-1"
                variant="outline-primary"
                @click="Close"
              >
                Close
              </b-button>
            </b-card-text>
          </b-card>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
  BFormCheckboxGroup,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from "bootstrap-vue";
import { Button, HasError, AlertError } from "vform/src/components/bootstrap5";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import _ from "lodash";
export default {
  data() {
    return {
      selected: [],
      search: {
        category_id: "",
        search_data: "",
        from_date: "",
        to_date: "",
        paginate: 10,
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100],

      form: new Form({
        id: "",
        name: null,
        email: null,
        password: null,
        profession: null,
        designation: null,
        company: null,
        description: null,
        image: null,
        status: true,
      }),

      image: this.$store.state.base_url + "images/teacher/default.svg",
      set_image: this.$store.state.base_url + "images/teacher/default.svg",
      teachers: {},

      select: {
        data: [],
        selected: false,
      },
      selectedAll: false,

      permission: {
        access: false,
        create: false,
        edit: false,
        delete: false,
      },
    };
  },
  mounted() {
    this.Permission();
    this.getResults();
  },
  methods: {
    loadImage(e) {
      this.form.image = e.target.files[0];
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    async getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const teachers = await this.callApi(
        "post",
        "/app/teacher?page=" + this.search.page,
        this.search
      );
      if (teachers.status == 200) {
        this.teachers = teachers.data.teachers;
      }
    },

    Delete() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't to delete it!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.DeleteAlert("/app/teacher/delete", this.select);
          this.select.data = [];
          this.getResults();
        }
      });
    },

    Close() {
      this.form.id = "";
      this.form.name = null;
      this.form.email = null;
      this.form.password = null;
      this.form.profession = null;
      this.form.designation = null;
      this.form.company = null;
      this.form.description = null;
      this.form.image = null;
      this.image = this.set_image;
      this.form.status = true;
    },

    async edit(id) {
      const teacher = await this.callApi("get", "/app/teacher/" + id);
      if (teacher.status == 200) {
        this.form.id = teacher.data.teacher.id;
        this.form.name = teacher.data.teacher.name;
        this.form.email = teacher.data.teacher.email;
        this.image = teacher.data.teacher.image
          ? this.$store.state.base_url + teacher.data.teacher.image
          : this.set_image;
        if (teacher.data.teacher.profile) {
          this.form.profession = teacher.data.teacher.profile.profession;
          this.form.designation = teacher.data.teacher.profile.designation;
          this.form.company = teacher.data.teacher.profile.company;
          this.form.description = teacher.data.teacher.profile.description;
        }
        this.form.status = teacher.data.teacher.status == true ? true : false;
      }
    },

    add() {
      let url = "";
      if (this.form.id == "") {
        url = "/app/teacher/store";
      } else {
        url = "/app/teacher/update";
      }
      this.form
        .post(url)
        .then(({ data }) => {
          this.getResults();
          this.s(data.message);
          this.Close();
          this.$Loading.finish();
        })
        .catch((e) => {
          this.$Loading.error();
        });
    },

    // Select Data for Delete
    selectAll(event) {
      if (event.target.checked == false) {
        this.select.data = [];
      } else {
        this.select.data = [];
        this.teachers.data.forEach((category) => {
          this.select.data.push(category.id);
        });
      }
    },

    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),

    // Paginate Data Gate
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },

    async Permission() {
      const permissions = await this.callApi("post", "/app/permission");
      permissions.data.permissions.forEach((permission) => {
        if (permission.slug == "teacher.access") {
          this.permission.access = true;
        }

        if (permission.slug == "teacher.create") {
          this.permission.create = true;
        }

        if (permission.slug == "teacher.edit") {
          this.permission.edit = true;
        }

        if (permission.slug == "teacher.destroy") {
          this.permission.delete = true;
        }
      });
    },
  },
  watch: {
    "search.category_id": function (val) {
      this.SearchData();
    },
    "search.search_data": function (val) {
      this.SearchData();
    },
    "search.from_date": function (val) {
      this.SearchData();
    },
    "search.to_date": function (val) {
      this.SearchData();
    },
    "search.paginate": function (val) {
      this.paginateChange();
    },
    "select.data": function (selected) {
      this.select.selected = selected.length > 0;
      this.selectedAll = selected.length == this.teachers.data.length;
    },
  },
  components: {
    Button,
    HasError,
    AlertError,
    vSelect,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
    BFormCheckboxGroup,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
  },
  computed: {
    categories() {
      return this.$store.getters.getSubCategory;
    },
  },
};
</script>

<style></style>
